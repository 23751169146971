import classNames from 'classnames'
import Button from 'components/button'
import { useFormik } from 'formik'
import { orderBy } from 'lodash'
import { useMutation } from 'react-query'
import * as Yup from 'yup'

import Select from 'components/select'
import { Link } from 'react-router-dom'
import FileUpload from '../../../components/FileUpload'
import useTranslationData from '../../translation/hooks/useTranslationData'
import ExcelTable from '../components/ExcelTable'
import { parseExcelData } from '../query'

const cardClass = 'bg-white px-4 py-4 rounded-xl h-fit'

const NovoImport = () => {
    const {
        translation: { t },
    } = useTranslationData()
    const parseExcelDataFunc = useMutation(['higeherDiscountrequest'], parseExcelData)

    const novoImportFormik = useFormik<any>({
        initialValues: {
            files: [],
            is_non_hyster: false,
        },
        validationSchema: Yup.object().shape({
            files: Yup.array().length(1, 'Please upload only one file'),
        }),
        onSubmit: async (values, { resetForm }) => {
            await parseExcelDataFunc.mutate({
                file: values.files[0],
                is_non_hyster: values.is_non_hyster,
            })
            resetForm()
        },
    })

    return (
        <div className="flex flex-col gap-8">
            <div className={classNames('flex flex-col gap-8', cardClass)}>
                <div className="text-secondary border-primary font-medium underline underline-offset-8 decoration-4 decoration-primary">
                    {t('api.upload_configurations')}
                </div>
                <div>
                    <div className="flex flex-col items-end gap-8">
                        <div className="w-full">
                            <FileUpload
                                files={novoImportFormik.values.files}
                                setFiles={files => novoImportFormik.setFieldValue('files', files)}
                                max={1}
                                error={
                                    novoImportFormik.errors.files && novoImportFormik.touched.files
                                        ? String(novoImportFormik.errors.files)
                                        : ''
                                }
                            />
                        </div>
                        {/* <Select
                            name="is_non_hyster"
                            label={t('api.is_non_hyster')}
                            options={[
                                { label: t('api.yes'), value: 'true' },
                                { label: t('api.no'), value: 'false' },
                            ]}
                            value={novoImportFormik.values.is_non_hyster}
                            onChange={e =>
                                novoImportFormik.setFieldValue(
                                    'is_non_hyster',
                                    e.target.value === 'true',
                                )
                            }
                        /> */}
                        <div className="w-fit flex items-center gap-4">
                            <Button
                                id="file_submit"
                                label={t('api.extract_data')}
                                variant="outline"
                                type="button"
                                isLoading={parseExcelDataFunc.isLoading}
                                onClick={() => novoImportFormik.handleSubmit()}
                            />
                            <Link to="/novo-configuration">
                                <Button
                                    id="back"
                                    label={t('api.back')}
                                    type="button"
                                    disabled={parseExcelDataFunc.isLoading}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {parseExcelDataFunc?.data?.data ? (
                <div className={classNames(cardClass)}>
                    <ExcelTable
                        excelData={
                            orderBy(
                                parseExcelDataFunc?.data?.data.data,
                                ['updated_at'],
                                ['desc'],
                            ) || []
                        }
                        isLoading={parseExcelDataFunc.isLoading}
                    />
                </div>
            ) : (
                <p className="text-center">{t('api.import_text')}</p>
            )}
        </div>
    )
}

export default NovoImport
